import { makeStyles } from "tss-react/mui";

export const useCompactLotCardStyles = makeStyles()((theme) => ({
    compactLotCard: {
        padding: theme.spacing(0.75),
    },
    contentSection: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-start",
        [theme.breakpoints.between(590, 850)]: {
            flexDirection: "column",
        },
        [theme.breakpoints.down(430)]: {
            flexDirection: "column",
        },
        [theme.breakpoints.between(850, 1120)]: {
            flexDirection: "column",
        },
    },
    emptyPricingContainer: {
        padding: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
        minHeight: 45,
        flex: 1,
    },
    imageContainer: {
        position: "relative",
        aspectRatio: "1.78",
        height: "auto",
        maxHeight: 128,
        [theme.breakpoints.down(430)]: {
            minHeight: 175,
        },
        [theme.breakpoints.between(590, 1120)]: {
            minHeight: 128,
        },
        [theme.breakpoints.between(1245, 1800)]: {
            minWidth: 228,
        },
        [theme.breakpoints.between(1230, 1245)]: {
            width: 222,
        },

        [theme.breakpoints.between(1190, 1230)]: {
            minWidth: 200,
        },

        [theme.breakpoints.between(1162, 1190)]: {
            minWidth: 195,
        },

        [theme.breakpoints.between(1120, 1162)]: {
            width: 190,
        },

        [theme.breakpoints.between(495, 590)]: {
            minWidth: 240,
        },
        [theme.breakpoints.between(461, 495)]: {
            minWidth: 210,
        },
        [theme.breakpoints.between(430, 461)]: {
            width: 180,
        },
    },
    image: {
        height: "100%",
        width: "100%",
        cursor: "pointer",
        objectFit: "scale-down",
    },
    rightHandSection: {
        paddingLeft: theme.spacing(0.75),
        width: "48%",

        [theme.breakpoints.between(1120, 1230)]: {
            width: "62%",
        },

        [theme.breakpoints.between(590, 1120)]: {
            width: "100%",
            paddingLeft: 0,
        },

        [theme.breakpoints.down(590)]: {
            width: "60%",
        },

        [theme.breakpoints.down(430)]: {
            width: "100%",
            paddingLeft: 0,
        },
    },
    headerContainer: {
        paddingTop: theme.spacing(0.4),
        paddingBottom: theme.spacing(0.4),
        [theme.breakpoints.down(430)]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        [theme.breakpoints.between(700, 1120)]: {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
        },
    },
    headerContent: {
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: 12,
        [theme.breakpoints.down("md")]: {
            fontSize: 11,
        },
        [theme.breakpoints.down(640)]: {
            fontSize: 12,
        },
    },
    reserveMetContainer: {
        height: 20,
        paddingTop: 5,
        paddingBottom: 5,
        [theme.breakpoints.down(430)]: {
            paddingTop: theme.spacing(1),
        },
    },
    cardFooterContainer: {
        display: "flex",
        alignItems: "space-between",
        flex: 1,
        [theme.breakpoints.down(365)]: {
            flexDirection: "column",
        },
        [theme.breakpoints.between(650, 1120)]: {
            flexDirection: "column",
        },
    },
    countdownTimer: {
        background: "#2b2b2b",
        borderRadius: 5,
        flex: 1,
        padding: 1,
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down(960)]: {
            paddingLeft: theme.spacing(1.5),
        },
        [theme.breakpoints.down(430)]: {
            paddingLeft: theme.spacing(1.5),
            padding: theme.spacing(0.5),
        },
    },
    countDownRenderer: {
        display: "flex",
        paddingBottom: 2,
        justifyContent: "space-around",
    },
    countDownRendererUnitItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
    },
    countDownRendererUnitValue: {
        fontSize: "1rem",
    },
    countDownRendererUnitSuffix: {
        fontSize: "0.4rem",
        [theme.breakpoints.down(430)]: {
            fontSize: "0.5rem",
        },
    },
    countDownRenderDisplayText: {
        fontSize: "0.5rem",
        [theme.breakpoints.down(430)]: {
            fontSize: "0.55rem",
        },
    },
}));
