import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { default as OriginalCountdown, CountdownProps as OriginalCountdownProps } from "react-countdown";

type CountdownProps = Omit<OriginalCountdownProps, "ref">;

const Countdown = ({ date, ...rest }: CountdownProps) => {
    const ref = useRef<OriginalCountdown>(null);

    const internalDate = useMemo(() => (date ? new Date(date) : null), [date]);

    const restart = useCallback((retryCount = 0) => {
        try {
            ref.current?.start();
        } catch (e) {
            console.log(e);
            if (retryCount < 5) setTimeout(() => restart(retryCount++), 100);
            console.log("Countdown=> Attempting to start timer");
        }
    }, []);

    useEffect(() => {
        if (internalDate) {
            restart();
        }
    }, [internalDate, restart]);

    return internalDate ? <OriginalCountdown date={internalDate} {...rest} ref={ref} /> : null;
};

export default Countdown;
